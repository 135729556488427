/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


.theme-smartvu.light .mat-mdc-paginator {
    background: transparent !important;
}

.mat-mdc-tab-group .mat-mdc-tab-body-content {
    padding: 24px 0;
}

.mat-mdc-tab-body-content {
    height: 100%;
    overflow: hidden !important;
}


// Mat Tab Bar Styling

.mat-mdc-tab-list {
    flex-grow: 0 !important;
    padding: 0px 5px;
}


.mat-mdc-tab-link-container {
    background: #ffffff;
    border-radius: 5px;
}

//

// Mat Tab Group Styling
.mat-mdc-tab-header {
    //padding: 0 40px;
}

.mat-mdc-tab-label-container {
    background: #ffffff;
    border-radius: 5px;
    margin: 0 0 !important;
}

//

.dot {
    height: 8px;
    width: 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}


.upload-button {
    background: #41c4ff;
    color: #ffffff;
    border-radius: 6px;

    .fuse-vertical-navigation-item-title {
        opacity: 1 !important;
    }

}

.studio-button {
    color: #41c4ff;
    border-radius: 6px;
    border: solid 1px;

    .fuse-vertical-navigation-item-title {
        opacity: 1 !important;
    }
}

.opacity-1 {
    opacity: 1 !important;
}

.mat-mdc-dialog-content {
    max-height: 88vh !important;
}

.share-embed-grid {
    grid-template-columns: 48px auto 40px;

    @screen sm {
        grid-template-columns: 48px auto 112px 72px;
    }

    @screen md {
        grid-template-columns: 48px 112px auto 112px 72px 72px;
    }

    @screen lg {
        grid-template-columns: 48px 112px auto 112px 240px 72px;
    }
}

.locations-grid {
    grid-template-columns: 48px auto 40px;

    @screen sm {
        grid-template-columns: 48px auto 112px 72px;
    }

    @screen md {
        grid-template-columns: 48px 112px auto 112px 72px;
    }

    @screen lg {
        grid-template-columns: 48px 224px auto 136px 136px 136px;
    }
}

.calendly-overlay {
    background-color: rgba(31, 31, 31, .8) !important;
}

.calendly-overlay .calendly-popup {
    max-height: 100% !important;
}


